@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --text: #000000;
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

[class="dark"] {
  --background: #1a1a1a;
  --text: #ffffff;
}

body {
  background-color: var(--background);
  color: var(--text);
  transition: background-color 0.3s ease, color 0.3s ease;
}

@font-face {
  font-family: "Suzuki";
  src: url("/public/fonts/Suzuki.otf") format("truetype");
}

body {
  font-family: "Suzuki", sans-serif;
}

/* Scroll animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.scroll-animate {
  opacity: 0;
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.3s;
}
