.wabtn {
  position: fixed;
  bottom: 80px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 999999999;
}

.whatsapp-link {
  cursor: pointer;
  height: 48px;
  width: auto;
  padding: 7px;
  color: #fff;
  background-color: #00e785;
  box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  font-size: 18px;
  font-weight: 600;
  animation: pulse 2.5s ease infinite;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
}

.whatsapp-link:hover::before {
  content: attr(data-tooltip);
  font-size: 16px;
  text-align: center;
  position: absolute;
  display: block;
  right: 50%;
  transform: translateX(50%);
  bottom: calc(100% + 10px);
  animation: fade-in 500ms ease;
  background: #00e785;
  border-radius: 4px;
  padding: 10px;
  color: #ffffff;
  z-index: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
